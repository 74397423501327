import { render, staticRenderFns } from "./TransferenciaLayout.vue?vue&type=template&id=3dbacf8f&scoped=true&"
import script from "./TransferenciaLayout.vue?vue&type=script&lang=js&"
export * from "./TransferenciaLayout.vue?vue&type=script&lang=js&"
import style0 from "./TransferenciaLayout.vue?vue&type=style&index=0&id=3dbacf8f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dbacf8f",
  null
  
)

export default component.exports